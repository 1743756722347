import { FC, useState } from 'react'
import DropDown from './DropDown'
import { Align } from 'types'

interface DropDownController {
  triggerNode: React.ReactChild
  align?: Align
  mouseLeave?: boolean
  customStyles?: { [key: string]: string }
  disable?: true
}

const DropDownController: FC<DropDownController> = ({
  children,
  triggerNode,
  align,
  mouseLeave,
  customStyles,
}) => {
  const [isMenuOpened, setMenuOpened] = useState(false)

  const togglePopup = (event: any) => {
    event.stopPropagation()
    setMenuOpened(!isMenuOpened)
  }

  const closePopup = (event: any) => {
    event.stopPropagation()
    setMenuOpened(false)
  }

  const onMouseLeave = (event: any) => {
    setMenuOpened(false)
  }

  return (
    <DropDown
      triggerNode={triggerNode}
      align={align}
      mouseLeave={mouseLeave}
      customStyles={customStyles}
      isMenuOpened={isMenuOpened}
      handleTriggerClick={togglePopup}
      handleOutsideClick={closePopup}
      handleMenuClick={closePopup}
      onMouseLeave={mouseLeave ? onMouseLeave : undefined}
    >
      {children}
    </DropDown>
  )
}

export default DropDownController
