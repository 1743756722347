export const headers = [
  { label: 'Email', id: 'email' },
  { label: 'SMS', id: 'sms' },
]

export const headerNames = {
  agreementEmail: 'Email',
  agreementSms: 'Sms',
  agreementTelegram: 'Telegram',
  agreementWhatsapp: 'Whatsapp',
}
export const sources = [
  'Ежемесячный дайджест новостей',
  'Приглашения на вебинары',
  'Статьи и разборы клинических случаев',
]

export const subtitleAgain =
  'Вы успешно отписаны от рассылки. Если вы сделали это по ошибке, нажмите на кнопку ниже, чтобы заново подписаться на рассылку.'
