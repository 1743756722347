import cx from 'classnames'

import styles from './Message.module.scss'

const Message = ({ text }: { text: string }) => {
  return (
    <div className={styles.msgWrap}>
      <div className={'subtitle'}>Сообщение</div>
      <div className={cx(styles.textWrap, 'text')}>{text}</div>
    </div>
  )
}

export default Message
