import { FC } from 'react'
import styles from './UnsubscribeTable.module.scss'
import useUnsubscribe from '../../hooks/useUnsubscribe/useUnsubscribe'
import Goodbye from './components/Goodbye/Goodbye'
import TagsChannelTable from './components/TagsChannelTable/TagsChannelTable'
import WelcomeBack from './components/WelcomeBack/WelcomeBack'
import { UnsubContext } from './UnsubContext'

const UnsubscribeTable: FC = () => {
  const {
    pageStatuses,
    email,
    page,
    subscriptions,
    subAgainStep,
    checkAllUnsub,
    headers,
    toggleCheck,
    changeAllChecks,
    setSubscriptions,
    handleSave,
    handleReturnToSubscribeWithAllChecks,
    handleResetToInit,
    toggleAllUnsubCheck,
  } = useUnsubscribe()

  const { GOODBY, WELCOME_BACK, UNSUBING } = pageStatuses

  const subscriptionsExist = subscriptions?.length > 0
  return (
    <div className={styles.pageWrap}>
      <UnsubContext.Provider
        value={{
          headers,
          pageStatuses,
          email,
          page,
          subscriptions,
          subAgainStep,
          checkAllUnsub,
          toggleCheck,
          changeAllChecks,
          setSubscriptions,
          handleSave,
          handleReturnToSubscribeWithAllChecks,
          handleResetToInit,
          toggleAllUnsubCheck,
        }}
      >
        {page === GOODBY ? (
          <Goodbye icon={'cat'} />
        ) : page === WELCOME_BACK ? (
          <WelcomeBack />
        ) : page === UNSUBING && subscriptionsExist ? (
          <div className={styles.tableWrap}>
            <TagsChannelTable />
          </div>
        ) : (
          <p>Что-то не так</p>
        )}
      </UnsubContext.Provider>
    </div>
  )
}

export default UnsubscribeTable
