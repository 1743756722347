import { FC } from 'react'
import OneRecipient from '../OneRecipient/OneRecipient'
import { TRecipient, TStatus, TStatusPriority } from 'pages/WhatsApp/types'

import styles from './Recipients.module.scss'

const statusPriority: TStatusPriority = { new: 0, canceled: 1, send: 1, old: 1 }

interface IRecipient {
  recipients: TRecipient[]
  updateStatus: (index: number, newStatus: TStatus) => void
}

const calcCount = (recipients: TRecipient[]) => {
  return recipients.length
}

const calcCompleted = (recipients: TRecipient[]) => {
  console.log({ recipients })
  return recipients.filter((recipient) => recipient.status === 'send').length
}

const Recipients: FC<IRecipient> = ({ recipients, updateStatus }) => {
  const sortFunc = (a: TRecipient, b: TRecipient) => {
    const statusA = a.status as TStatus
    const statusB = b.status as TStatus
    return statusPriority[statusA] - statusPriority[statusB]
  }

  return (
    <div className={styles.wrap}>
      <div className={styles.title}>
        <div className={'subtitle'}>Получатели</div>
        <span>{`${calcCompleted(recipients)}/${calcCount(recipients)}`}</span>
      </div>
      {recipients.sort(sortFunc).map((rec, index) => {
        return (
          <OneRecipient
            key={index}
            recipient={rec}
            updateStatus={(status) => updateStatus(index, status)}
          />
        )
      })}
    </div>
  )
}

export default Recipients
