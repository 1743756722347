import { BlockDDConfigElement, EBlockStatus, TConfig, TValidateErrors } from '../types'

export const getBlockDDConfig = (config: TConfig, requireErrors: TValidateErrors) => {
  return config.reduce((acc, block) => {
    const { id, title } = block
    const status = !requireErrors[id]
      ? EBlockStatus.IDLE
      : requireErrors[id].length > 0
      ? EBlockStatus.ERROR
      : EBlockStatus.SUCCES
    // const haveAtLeastOneErrorInBlock = requireErrors[id] ? requireErrors[id].length > 0 : false
    acc = [...acc, { id, title, status: status }]
    return acc
  }, [] as BlockDDConfigElement[])
}
