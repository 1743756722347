import { FC } from 'react'
import cx from 'classnames'
import styles from './Table.module.scss'

interface ICell {
  main?: boolean
  head?: boolean
  check?: boolean
  modificator?: string
}

export const Cell: FC<ICell> = ({ children, main, head, check, modificator }) => {
  return (
    <div
      className={cx(styles.cell, styles.mainCell, modificator, {
        [styles.headCell]: head,
        [styles.mainCell]: main,
        [styles.checkCell]: check,
      })}
    >
      {children}
    </div>
  )
}
