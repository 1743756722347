import { FC } from 'react'
import cx from 'classnames'

import { IconRobot, IconOnpoint } from 'assets/icons'
import styles from './Survey.module.scss'
import SurveyBuilder from './SurveyBuilder/SurveyBuilder'
import useSurvey, { blockerText } from './useStore'
import { SurveyContext } from './Context'

import besingLogoWhite from './img/besins_logo_white.png'

const Survey: FC = () => {
  const {
    answers,
    setNewAnswers,
    finished,
    config,
    surveyName,
    handleSubmit,
    currentPage,
    goToPrevPage,
    goToNextPage,
    requireErrors,
    goToPage,
  } = useSurvey()

  const client = process.env.REACT_APP_CLIENT

  return (
    <SurveyContext.Provider
      value={{
        answers,
        setNewAnswers,
        finished,
        config,
        surveyName,
        handleSubmit,
        currentPage,
        goToPrevPage,
        goToNextPage,
        requireErrors,
        goToPage,
      }}
    >
      <div className={styles.survey}>
        <div className={styles.head}>
          <div className={styles.headContainer}>
            <p className={cx(styles.name, 'title')}>{surveyName}</p>
            {client === 'BESINS' ? (
              <img src={besingLogoWhite} className={styles.besinsLogo} />
            ) : (
              <IconOnpoint />
            )}
          </div>
        </div>
        {!finished ? (
          <SurveyBuilder />
        ) : (
          <div className={styles.finishMessage}>
            <span className={'text_2_hl_1'}>{blockerText}</span>
            <IconRobot className={styles.iconRobot} />
          </div>
        )}
      </div>
    </SurveyContext.Provider>
  )
}

export default Survey
