export const SERVER = process.env.REACT_APP_SERVER_URL

export const MAIN_URL = '/pages'

export const WHATSAPP_URL = `/comm/whatsapp` // /id
export const UNSUB_URL = `/comm/track/user` // id

export const WHATSAPP_ROUTE = '/wa/:waId'
export const UNSUB_ROUTE = '/unsubscribe/:unsubId'
export const UNSUB_ROUTE_DEFAULT = '/unsubscribe_default/:unsubId'
export const SURVEY_ROUTE = '/survey/:surveyId'
export const SURVEY_BASE = 'comm/survey/user'
export const SURVEY_GET = `${SURVEY_BASE}/one` //:id
export const SURVEY_SUBMIT = `${SURVEY_BASE}/complete` //:id
