import { FC, ChangeEvent } from 'react'
import { EQuestionType, TAnswer, TQuestionCheckbox, TQuestionRadio } from 'pages/Survey/types'
import styles from './AnswerRadioCheckType.module.scss'
import RadioCheckboxSelector from 'components/RadioCheckboxSelector/RadioCheckboxSelector'

interface IAnswerRadioCheckType {
  question: TQuestionRadio | TQuestionCheckbox
  asnwers: TAnswer[]
  setAnswer: (id: string, answer: string) => void
  setCheckAnswer: (id: string, answer: string, ckecked: boolean) => void
}

const AnswerRadioCheckType: FC<IAnswerRadioCheckType> = ({
  question,
  setAnswer,
  setCheckAnswer,
  asnwers,
}) => {
  const { id: questionId, answerChoice, type } = question
  // const answer = asnwerObj ? (asnwerObj.answer as string[]) : []
  const currentAnswersValues = asnwers.map((oneAnswer) => {
    return oneAnswer.answer
  })

  const handleClickSelector = (
    event: ChangeEvent<HTMLInputElement>,
    checked: boolean,
    name?: string
  ) => {
    if (!name) return
    if (type === EQuestionType.RADIO) {
      setAnswer(questionId, name)
    } else if (type === EQuestionType.CHECKBOX) {
      setCheckAnswer(questionId, name, checked)
    }
  }

  return (
    <form className={styles.variants}>
      {answerChoice.map((answerVariant) => {
        const itemId = `${answerVariant}_${questionId}`
        const checked = currentAnswersValues.includes(answerVariant)

        return (
          <div key={answerVariant + type} className={styles.variant}>
            <RadioCheckboxSelector
              id={itemId}
              name={answerVariant}
              label={answerVariant}
              checked={checked}
              onClick={handleClickSelector}
              typography="text"
              type={type}
            />
          </div>
        )
      })}
    </form>
  )
}

export default AnswerRadioCheckType
