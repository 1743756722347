/* eslint-disable  @typescript-eslint/no-explicit-any */
import axios from 'axios'
import axiosRetry from 'axios-retry'
import { SERVER } from 'constants/url'

const Config = {
  HEADERS: {
    'Content-Type': 'application/json; charset=utf-8',
  },
}

const axiosInstance = axios.create({
  withCredentials: true,
  baseURL: `${SERVER}/api`,
})

axiosRetry(axiosInstance, { retries: 3, retryDelay: axiosRetry.exponentialDelay })

const getRequest = async (URL: string, signal?: AbortSignal): Promise<any> => {
  const res = await axiosInstance({
    method: 'get',
    url: URL,
    headers: Config.HEADERS,
    signal,
  })
  return res
}

const postRequest = async (
  URL: string,
  headers: any,
  data?: any,
  signal?: AbortSignal
): Promise<any> =>
  await axiosInstance({
    method: 'post',
    url: URL,
    headers,
    data,
    signal,
  }).catch((err) => {
    console.log(err)
  })

const patchRequest = async (
  URL: string,
  headers: any,
  data?: any,
  signal?: AbortSignal
): Promise<any> =>
  await axiosInstance({
    method: 'patch',
    url: URL,
    headers,
    data,
    signal,
  })

const getAxiosSingle = async (URL: string, signal?: AbortSignal): Promise<any> => {
  try {
    const result = await getRequest(URL, signal)
    return result.data
  } catch (err) {
    console.warn(err)
    return err
  }
}

const postAxiosSingle = async (URL: string, data?: any, signal?: AbortSignal): Promise<any> => {
  try {
    const result = await postRequest(URL, Config.HEADERS, data, signal)
    return result
  } catch (err) {
    console.warn(err)
    return err
  }
}

const patchAxiosSingle = async (URL: string, data?: any, signal?: AbortSignal): Promise<any> => {
  try {
    const result = await patchRequest(URL, Config.HEADERS, data, signal)
    return result
  } catch (err) {
    console.warn(err)
    return err
  }
}

export { getRequest, getAxiosSingle, postAxiosSingle, postRequest, patchAxiosSingle }
