import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'

import PAGES, { START_PAGE } from 'pages'

import './assets/styles/App.scss'
import './assets/styles/typography.scss'
import './assets/styles/fonts.scss'
import { useAlertBox } from 'hooks/useAlertBox'
import { AlertContext } from 'context/AlertContext'
import AlertBox from 'components/AlertBox/AlertBox'
import { MAIN_URL } from 'constants/url'

function App() {
  const alertBox = useAlertBox()

  console.log('client', process.env.REACT_APP_CLIENT)

  return (
    <div className="App">
      <AlertContext.Provider value={alertBox}>
        <Switch>
          {PAGES.map((page) => {
            const { Component, link } = page
            return (
              <Route path={link} key={link}>
                <Component />
              </Route>
            )
          })}
          <Route render={() => <Redirect to={MAIN_URL} />} />
        </Switch>
        <AlertBox />
      </AlertContext.Provider>
    </div>
  )
}

export default App
