import { TSubscription } from 'hooks/useUnsubscribe/types'
import { ChangeEvent, createContext, useContext } from 'react'

export const UnsubContext = createContext(
  {} as {
    headers: string[]
    subscriptions: TSubscription[]
    toggleAllUnsubCheck: () => void
    toggleCheck: (event: ChangeEvent<HTMLInputElement>) => void
    [key: string]: any
  }
)

const useUnsubContext = () => useContext(UnsubContext)

export default useUnsubContext
