import { useEffect, useState } from 'react'
import { TAnswer, TConfig, TValidateErrors } from './types'
import { useParams } from 'react-router-dom'
import useAlertContext from 'context/AlertContext'
import { getContentSurvey, patchSubmitSurvey } from './utils'

const defaultName = 'Опрос'
export const blockerText = 'Спасибо за прохождение опроса!'

const useStore = () => {
  const { surveyId } = useParams<{ surveyId?: string }>()
  const { setAlertBox } = useAlertContext()
  const [answers, setAnswers] = useState<TAnswer[]>([])
  const [finished, setFinished] = useState(false)
  const [config, setConfig] = useState<TConfig>([])
  const [surveyName, setSurveyName] = useState(defaultName)
  const [requireErrors, setRequireErrors] = useState<TValidateErrors>({})
  const [currentPage, setCurrentPage] = useState(0)

  const adaptAnswersToSend = (answersToAdapt: TAnswer[]) => {
    return answersToAdapt.map((iterateAnswer) => {
      const { question, answer } = iterateAnswer
      const answerConcat = Array.isArray(answer) ? answer.join(';') : answer
      return {
        question,
        answer: answerConcat,
      }
    })
  }

  const getBlock = (blockId: string) => {
    return config.filter((iterate) => {
      const { id } = iterate
      return id === blockId
    })
  }

  const restructureAnswersArrayToObject = () =>
    answers.reduce((acc, iterate) => {
      const { question, answer } = iterate
      acc[question] = answer
      return acc
    }, {} as { [key: string]: string | string[] })

  const validateByRequire = (targetId?: string) => {
    const filteredConfig = targetId ? getBlock(targetId) : config
    const answeredQuestionId = restructureAnswersArrayToObject()

    const errors = filteredConfig.reduce((acc, block) => {
      const { questions, id: blockId } = block
      const idsOfNotValidQuestion = questions
        .map((question) => {
          const { require, id: questionId } = question
          if (!require) return
          const haveAnswer = answeredQuestionId[questionId]?.length > 0
          if (haveAnswer) return
          return questionId
        })
        .filter((e) => e) as string[]
      acc[blockId] = idsOfNotValidQuestion
      return acc
    }, {} as TValidateErrors)
    const updRequiredErr = { ...requireErrors, ...errors }
    setRequireErrors(updRequiredErr)
    return updRequiredErr
  }

  const handleSubmit = () => {
    if (!surveyId) return
    const errors = validateByRequire()
    const haveError = Object.values(errors).filter((arr) => arr.length > 0).length > 0
    console.log(errors, haveError)

    if (haveError) {
      setAlertBox({
        message: 'Дайте ответ на все обязательные вопросы',
        icon: undefined,
      })
      return
    }
    const adaptedAnswers = adaptAnswersToSend(answers)
    const response = patchSubmitSurvey(surveyId, { answers: adaptedAnswers })
    response.then((res) => {
      if (!res) return
      const { finish } = res
      setFinished(finish)
    })
  }

  const goToPage = (order: number) => {
    const { id: blockId } = config[currentPage]
    validateByRequire(blockId)
    setCurrentPage(order)
  }

  const goToNextPage = () => {
    goToPage(currentPage + 1)
  }

  const goToPrevPage = () => {
    goToPage(currentPage - 1)
  }

  const getAndSetData = () => {
    if (!surveyId) return
    const response = getContentSurvey(surveyId)
    response.then((res) => {
      if (!res) return
      const { survey, answers: answersResponse, finish } = res
      finish && setFinished(finish)
      answersResponse && setAnswers(answersResponse)
      survey && setConfig(survey.surveyConfig as TConfig)
      survey && setSurveyName(survey.name)
    })
  }

  const setNewAnswers = (newAnswers: TAnswer[]) => {
    setAnswers(newAnswers)
  }

  useEffect(() => {
    validateByRequire()
    console.log({ answers })
  }, [answers])

  useEffect(() => {
    console.log({ config })
  }, [config])

  useEffect(() => {
    getAndSetData()
  }, [])

  return {
    surveyId,
    answers,
    finished,
    config,
    surveyName,
    requireErrors,
    currentPage,
    setNewAnswers,
    setFinished,
    setConfig,
    setSurveyName,
    setRequireErrors,
    adaptAnswersToSend,
    validateByRequire,
    handleSubmit,
    goToNextPage,
    goToPrevPage,
    goToPage,
  }
}
export default useStore
