import { FC } from 'react'
import { useParams } from 'react-router-dom'
import cx from 'classnames'

import Button from 'components/Button/Button'
import DropDown from 'components/DropDown/DropDownController'
import { WHATSAPP_URL } from 'constants/url'
import { TRecipient, TStatus, WAUnsubDto } from 'pages/WhatsApp/types'
import { postAxiosSingle } from 'utils/axios'

import styles from './OneRecipient.module.scss'
import ddStyles from 'components/DropDown/DropDown.module.scss'

interface IOneRec {
  recipient: TRecipient
  updateStatus: (newStatus: TStatus) => void
}

const OneRecipient: FC<IOneRec> = ({ recipient, updateStatus /*, text */ }) => {
  const { waId } = useParams<{ waId?: string }>()
  const { userId, name, speciality, status, phone, text } = recipient

  const isSendable = status === 'new' || status === 'old'
  const statusToRender = status === 'send' ? 'отправлено' : 'отменено'

  const handleSend = async () => {
    const textEncoded = encodeURIComponent(text)

    // window.open(`https://wa.me/${phone}?text=${textEncoded}`, '_blank')
    // window open оставляет пустую страницу в ios
    // а эта ёбань не оставляет

    const a = document.createElement('a')
    a.href = `https://wa.me/${phone}?text=${textEncoded}`
    a.setAttribute('data-action', 'share/whatsapp/share')
    a.setAttribute('target', '_blank')
    a.click()
    a.remove()

    await postAxiosSingle(`${WHATSAPP_URL}/${waId}`, { status: 'send', id: userId })
    updateStatus('send')
  }

  const handleChange = () => {
    updateStatus('old')
  }

  const handleOptionPick = async (reason: string) => {
    await postAxiosSingle(`${WHATSAPP_URL}/${waId}`, { status: 'canceled', reason, id: userId })
    updateStatus('canceled')
  }

  const RightButtons = () => {
    if (isSendable)
      return (
        <>
          <Button onClick={handleSend}>Отправить</Button>
          <DropDown triggerNode={<Button secondary>Отменить</Button>}>
            <div className={ddStyles.container}>
              {Object.values(WAUnsubDto).map((option, index) => (
                <button
                  className={cx(ddStyles.element, 'text')}
                  onClick={() => handleOptionPick(option)}
                  key={index}
                >
                  {option}
                </button>
              ))}
            </div>
          </DropDown>
        </>
      )

    return (
      <>
        <p className={cx('text', styles.status)}>Статус: {statusToRender}</p>
        <Button onClick={handleChange} secondary>
          Изменить
        </Button>
      </>
    )
  }

  return (
    <div
      className={cx(styles.wrap, {
        [styles.wrapGray]: !isSendable,
      })}
    >
      <div
        className={cx(styles.leftBlock, {
          [styles.leftBlockGray]: !isSendable,
        })}
      >
        <div className={'text_hl'}>{name}</div>
        <div className={cx(styles.speciality, 'text')}>{speciality}</div>
      </div>

      <div className={styles.rightBlock}>
        <RightButtons />
      </div>
    </div>
  )
}

export default OneRecipient
