import { FC, memo, useEffect, useRef } from 'react'
import cx from 'classnames'

import PortalWrap from 'containers/PortalWrap'

import useAlertContext from 'context/AlertContext'
import { IconSuccess, IconWarning } from 'assets/icons'
import styles from './AlertBox.module.scss'

export enum AlertBoxIcons {
  SUCCESS,
  WARNING,
}

const { WARNING, SUCCESS } = AlertBoxIcons
const TIME = 4000

const iconVars = {
  [WARNING]: <IconWarning />,
  [SUCCESS]: <IconSuccess />,
}

const AlertBox: FC = () => {
  const { alertBox, hideAlertBox } = useAlertContext()
  const { isOpen, message, icon } = alertBox
  const timerId = useRef<NodeJS.Timeout>()

  useEffect(() => {
    if (!isOpen) return

    clearTimeout(timerId.current)
    timerId.current = setTimeout(hideAlertBox, TIME)

    return () => clearTimeout(timerId.current)
  }, [alertBox])

  if (!isOpen) return null

  return (
    <PortalWrap>
      <div className={styles.wrapper}>
        {iconVars[icon ?? WARNING]}
        <div
          className={cx(styles.message, 'text_05')}
          dangerouslySetInnerHTML={{ __html: message }}
        ></div>
      </div>
    </PortalWrap>
  )
}

export default AlertBox
