import { FC } from 'react'
import cx from 'clsx'
import styles from '../../UnsubscribeTable.module.scss'
import Button from 'components/Button/Button'
import Table from '../Table/Table'
import useUnsubContext from 'pages/UnsubscribeTable/UnsubContext'

const TagsChannelTable: FC = () => {
  const { email, handleResetToInit, handleSave } = useUnsubContext()

  return (
    <>
      <p className={'title'}>Вы уверены, что хотите отписаться от рассылки?</p>
      <p className={cx(styles.email)}>{email}</p>

      <Table />

      <div className={styles.btnsWrap}>
        <Button onClick={handleSave}>
          <span className="text_14_400">Сохранить</span>
        </Button>
        <Button secondary onClick={handleResetToInit}>
          <span className="text_14_400">Отменить</span>
        </Button>
      </div>
    </>
  )
}

export default TagsChannelTable
