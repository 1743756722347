import { SURVEY_GET, SURVEY_SUBMIT } from 'constants/url'
import { getAxiosSingle, patchAxiosSingle } from 'utils/axios'

export const getContentSurvey = async (contentId: string) => {
  if (!contentId) return
  const content = await getAxiosSingle(`${SURVEY_GET}/${contentId}`)
  const { survey, answers, finish } = content
  const response = {
    survey,
    answers,
    finish,
  }
  return response
}

export const patchSubmitSurvey = async (contentId: string, data: any) => {
  if (!contentId) return
  const content = await patchAxiosSingle(`${SURVEY_SUBMIT}/${contentId}`, data)
  const { survey, answers, finish } = content.data
  const response = {
    survey,
    answers,
    finish,
  }
  return response
}
