import WhatsApp from './WhatsApp/WhatsApp'
import UnsubscribeTable from './UnsubscribeTable/UnsubscribeTable'
import Survey from './Survey/Survey'

import { UNSUB_ROUTE, WHATSAPP_ROUTE, SURVEY_ROUTE, MAIN_URL } from 'constants/url'
import Main from './Main/Main'
import Unsubscribe from './Unsubscribe/Unsubscribe'

const client = process.env.REACT_APP_CLIENT

const commonPage = {
  WHATSAPP: {
    Component: WhatsApp,
    link: WHATSAPP_ROUTE,
  },
  SURVEY: {
    Component: Survey,
    link: SURVEY_ROUTE,
  },
  MAIN: {
    Component: Main,
    link: MAIN_URL,
  },
}

const pagesBesins = {
  UNSUBCRIBE: {
    Component: UnsubscribeTable,
    link: UNSUB_ROUTE,
  },
}

const pagesOnpoint = {
  UNSUBCRIBE: {
    Component: Unsubscribe,
    link: UNSUB_ROUTE,
  },
}
const clientPages = client === 'BESINS' ? pagesBesins : pagesOnpoint

const PAGES_DATA = {
  ...clientPages,
  ...commonPage,
}

const PAGES = Object.values(PAGES_DATA)
const CURRENT_PAGE = process.env.REACT_APP_PAGE as keyof typeof PAGES_DATA
const START_PAGE = PAGES_DATA[CURRENT_PAGE]?.link || MAIN_URL

export { START_PAGE, PAGES_DATA }
export default PAGES
