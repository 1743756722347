import { FC } from 'react'
import cx from 'classnames'

import styles from './Button.module.scss'
import themes from './ButtonThemes.module.scss'

export interface IButton extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  modificator?: string | string[]
  secondary?: boolean
  typography?: string
}

const Button: FC<IButton> = ({
  typography,
  onClick,
  modificator,
  children,
  type,
  secondary,
  ...props
}) => {
  return (
    <button
      className={cx(styles.button, typography, modificator, {
        [themes.theme_secondary]: secondary,
      })}
      onClick={onClick}
      type={type}
      {...props}
    >
      {children}
    </button>
  )
}

export default Button
