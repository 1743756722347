import { FC } from 'react'

import { Cell } from './Cell'
import { headerNames } from '../../constants'

import styles from './Table.module.scss'
import { TSubscription } from 'hooks/useUnsubscribe/types'
import RadioCheckboxSelector, {
  CHECKBOX,
} from 'components/RadioCheckboxSelector/RadioCheckboxSelector'
import useUnsubContext from 'pages/UnsubscribeTable/UnsubContext'

const Table: FC = () => {
  const {
    headers,
    subscriptions,
    checkAllUnsub,
    setSubscriptions,
    toggleCheck,
    toggleAllUnsubCheck,
  } = useUnsubContext()

  return (
    <div className={styles.table}>
      <div className={styles.head}>
        <div className={styles.row}>
          <Cell main head />
          {headers.map((header, index) => {
            const title = headerNames[header as keyof typeof headerNames]
            if (!title) return
            return (
              <Cell head key={index} modificator={styles.headEqual}>
                {title}
              </Cell>
            )
          })}
        </div>
      </div>
      <div className={styles.tbody}>
        {subscriptions.map((row, index) => {
          const { title, description, deletedAt, id, updatedAt, createdAt, ...agreements } = row

          return (
            <div className={styles.row} key={index}>
              <Cell main>{title}</Cell>
              {Object.keys(agreements).map((agreement) => {
                const agreementId = `${agreement}_${index}`
                const isChecked = row[agreement as keyof TSubscription] as boolean
                const dataAttributes = {
                  'data-title': title,
                  'data-type': agreement,
                }

                return (
                  <Cell check key={agreementId}>
                    <div className={styles.checkContainer}>
                      <RadioCheckboxSelector
                        id={`${title}_${agreement}`}
                        name={title}
                        checked={isChecked}
                        onClick={toggleCheck}
                        typography="text"
                        type={CHECKBOX}
                        attributes={dataAttributes}
                      />
                    </div>
                  </Cell>
                )
              })}
            </div>
          )
        })}
      </div>
      <div className={styles.foot}>
        <div className={styles.footRow}>
          <RadioCheckboxSelector
            name={'checkedAll'}
            checked={checkAllUnsub}
            onClick={toggleAllUnsubCheck as () => void}
            typography="text"
            type={CHECKBOX}
          />
          <span className={styles.label} onClick={toggleAllUnsubCheck as () => void}>
            Отписаться от всех уведомлений
          </span>
        </div>
      </div>
    </div>
  )
}

export default Table
