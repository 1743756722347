import { FC } from 'react'
import cx from 'clsx'

import styles from './Goodbye.module.scss'
import Button from 'components/Button/Button'
import { subtitleAgain } from 'pages/UnsubscribeTable/constants'
import { IconCat } from 'assets/icons'
import { IconRobotReading } from 'assets/icons'
import useUnsubContext from 'pages/UnsubscribeTable/UnsubContext'

interface IGoodbye {
  icon: 'cat' | 'robot'
}

const Goodbye: FC<IGoodbye> = ({ icon }) => {
  const { email, handleReturnToSubscribeWithAllChecks } = useUnsubContext()
  const Icon = icon === 'cat' ? IconCat : IconRobotReading
  return (
    <div className={styles.content}>
      <div className={styles.textBlock}>
        <p className={cx(styles.text, 'text_36_600')}>Мы будем скучать...</p>
        <p className={cx(styles.email, 'text_2')}>{email}</p>
        <p className={cx(styles.email, 'text_2')}>{subtitleAgain}</p>

        <div className={styles.btnsWrap}>
          <Button onClick={handleReturnToSubscribeWithAllChecks}>
            <span className="text">Подписаться снова</span>
          </Button>
        </div>
      </div>
      <div className={styles.iconContainer}>
        <Icon />
      </div>
    </div>
  )
}

export default Goodbye
