import { ChangeEvent, FC } from 'react'
import cx from 'classnames'

import { TObj } from 'types'
import styles from './RadioCheckboxSelector.module.scss'

export const RADIO = 'RADIO'
export const CHECKBOX = 'CHECKBOX'

interface IRadioCheckboxSelector {
  id?: string
  name?: string
  label?: string
  width?: string
  placeholder?: string
  checked?: boolean
  required?: boolean
  onClick: (event: ChangeEvent<HTMLInputElement>, checked: boolean, name?: string) => void
  customStyles?: TObj<string>
  modificator?: string
  labelModificator?: string
  typography?: string
  type: typeof RADIO | typeof CHECKBOX
  attributes?: TObj<string>
}

const RadioCheckboxSelector: FC<IRadioCheckboxSelector> = ({
  id,
  name,
  label,
  width,
  onClick,
  required = false,
  checked = false,
  customStyles,
  modificator,
  labelModificator,
  typography,
  type,
  attributes,
}) => {
  const clickHandler = (event: ChangeEvent<HTMLInputElement>) => {
    onClick(event, checked, name)
  }

  return (
    <div style={{ width, ...customStyles }} className={cx(styles.wrapper, modificator)}>
      <div className={styles.inputWrapper}>
        <input
          className={styles.input}
          id={id}
          name={name}
          type={type}
          required={required}
          onChange={clickHandler}
          checked={checked}
          {...attributes}
        />
        {type === RADIO && <label className={styles.radioLabel} />}
      </div>
      {label && (
        <label className={cx(styles.label, labelModificator, typography)} htmlFor={id}>
          {label}
        </label>
      )}
    </div>
  )
}

export default RadioCheckboxSelector
