import { FC } from 'react'
import cx from 'classnames'

import { TBlock } from '../types'
import styles from './Block.module.scss'

interface IBlock {
  block: TBlock
  blockNumber: number
}

const Block: FC<IBlock> = ({ block, blockNumber }) => {
  const { questions, title, description } = block
  const haveAtLeastOneRequiredQuestion = questions.some((question) => {
    return question.require
  })
  const haveDescription = Boolean(description)

  return (
    <div className={styles.block}>
      <div className={styles.head}>
        <span className={cx(styles.name, 'text_1_hl_2')}>{`Блок ${blockNumber}`}</span>
      </div>
      <div className={styles.body}>
        <span className={cx('text_2_hl')}>{title}</span>
        {haveDescription && <span className={styles.multiline}>{description}</span>}
        {haveAtLeastOneRequiredQuestion && (
          <p className={styles.requereReminder}>* Вопрос, обязательный для ответа</p>
        )}
      </div>
    </div>
  )
}

export default Block
