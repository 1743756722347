import { FC, ChangeEvent } from 'react'
import TextAreaAutosize from 'components/TextAreaAutosize/TextAreaAutosize'
import { TAnswer, TQuestionText } from 'pages/Survey/types'

interface IAnswerTextType {
  question: TQuestionText
  setAnswer: (id: string, answer: string) => void
  asnwers: TAnswer[]
}

const AnswerTextType: FC<IAnswerTextType> = ({ question, setAnswer, asnwers }) => {
  const asnwer = asnwers[0]
  const { id: questionId } = question

  const handleChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    const { value } = event.target
    setAnswer(questionId, value)
  }

  const currentAnswer = asnwer ? asnwer.answer : ''

  return (
    <TextAreaAutosize onChange={handleChange} value={currentAnswer} placeholder={'Введите текст'} />
  )
}

export default AnswerTextType
