import { IAlertBox } from 'context/AlertContext'
import { useState } from 'react'

const INIT_ALERTBOX: IAlertBox = {
  message: '',
  icon: undefined,
  isOpen: false,
}

export const useAlertBox = () => {
  const [alertBox, setAlert] = useState(INIT_ALERTBOX)
  const setAlertBox = (config: IAlertBox) => setAlert({ isOpen: true, ...config })
  const hideAlertBox = () => setAlert(INIT_ALERTBOX)
  return { alertBox, setAlertBox, hideAlertBox }
}
