import { FC } from 'react'
import cx from 'classnames'

import { TAnswer, TQuestionDropdown } from 'pages/Survey/types'
import DropDown from 'components/DropDown/DropDownController'
import Button from 'components/Button/Button'
import buttonThemes from 'components/Button/ButtonThemes.module.scss'
import ddStyles from 'components/DropDown/DropDown.module.scss'
import styles from './AnswerDropdown.module.scss'
import { Align } from 'types'
import { IconArrow } from 'assets/icons'

interface IAnswerDropdown {
  question: TQuestionDropdown
  setAnswer: (id: string, answer: string) => void
  asnwers: TAnswer[]
}

const AnswerDropdown: FC<IAnswerDropdown> = ({ question, setAnswer, asnwers }) => {
  const asnwer = asnwers[0]
  const { id: questionId, answerChoice } = question

  const handleChange = (value: string) => {
    setAnswer(questionId, value)
  }

  const currentAnswer = asnwer ? asnwer.answer : 'Выберите вариант'

  return (
    <DropDown
      align={Align.FULL}
      triggerNode={
        <Button modificator={buttonThemes.theme_like_input} typography={'text'}>
          {currentAnswer}
          <IconArrow className={cx(styles.arrow)} />
        </Button>
      }
    >
      <div className={ddStyles.container}>
        {answerChoice.map((option, index) => (
          <button
            className={cx(ddStyles.element, 'text', styles.element)}
            onClick={() => handleChange(option)}
            key={index}
          >
            {option}
          </button>
        ))}
      </div>
    </DropDown>
  )
}

export default AnswerDropdown
