import { FC } from 'react'
import cx from 'classnames'

import { EQuestionType, TAnswer, TQuestion } from '../types'
import styles from './Question.module.scss'
import AnswerText from '../answers/AnswerTextType/AnswerTextType'
import AnswerRadioCheck from '../answers/AnswerRadioCheckType/AnswerRadioCheckType'
import AnswerDropdown from '../answers/AnswerDropdown/AnswerDropdown'
import useSurveyContext from '../Context'

interface IQuestion {
  question: TQuestion
  blockId: string
  questionId: string
  asnwers: TAnswer[]
  handleChangeOneAnswer: (id: string, answer: string) => void
  handleChangeCheckAnswer: (id: string, answer: string, ckecked: boolean) => void
}

const Question: FC<IQuestion> = ({
  asnwers,
  blockId,
  questionId,
  question,
  handleChangeOneAnswer,
  handleChangeCheckAnswer,
}) => {
  const { requireErrors } = useSurveyContext()

  const { title, type, require } = question

  const thisQeustionHaveError = requireErrors[blockId]?.includes(questionId)

  const renderQuestionAnswer = () => {
    if (type === EQuestionType.TEXT)
      return <AnswerText asnwers={asnwers} question={question} setAnswer={handleChangeOneAnswer} />
    if (type === EQuestionType.RADIO || type === EQuestionType.CHECKBOX)
      return (
        <AnswerRadioCheck
          asnwers={asnwers}
          question={question}
          setAnswer={handleChangeOneAnswer}
          setCheckAnswer={handleChangeCheckAnswer}
        />
      )
    if (type === EQuestionType.DROPDOWN)
      return (
        <AnswerDropdown asnwers={asnwers} question={question} setAnswer={handleChangeOneAnswer} />
      )
  }

  return (
    <div className={cx(styles.question, { [styles.error]: thisQeustionHaveError })}>
      <div className={styles.body}>
        <span className={styles.title}>
          {title} {require && <span className={styles.requireStar}>*</span>}
        </span>
        <div className={styles.answerContainer}>{renderQuestionAnswer()}</div>
      </div>
    </div>
  )
}

export default Question
