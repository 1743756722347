import { createContext, useContext } from 'react'

export interface IAlertBox {
  message: string
  isOpen?: boolean
  icon: AlertBoxIcons | undefined
}

export enum AlertBoxIcons {
  SUCCESS,
  WARNING,
}

interface IAlertBoxContext {
  alertBox: IAlertBox
  setAlertBox: (config: IAlertBox) => void
  hideAlertBox: () => void
}

export const AlertContext = createContext({} as IAlertBoxContext)

const useAlertContext = () => useContext(AlertContext)

export default useAlertContext
