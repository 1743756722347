import { WHATSAPP_URL } from 'constants/url'
import { FC, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { getAxiosSingle } from 'utils/axios'
import Message from './components/Message/Message'
import Recipients from './components/Recipients/Recipients'
import { TRecipient, TStatus, TRecipientBack } from './types'
import { dataMock } from '../../constants/mock'

import styles from './WhatsApp.module.scss'

const name = 'WhatsApp рассылка'

const parseRecipients = (recs: TRecipientBack[]) => {
  return recs.map((rec) => ({
    userId: rec.userId || '',
    name: `${rec.lastName || ''} ${rec.firstName || ''} ${rec.middleName || ''}`,
    speciality: rec.speciality || '_',
    phone: rec.phone || '',
    status: 'new',
    text: rec.text,
  })) as TRecipient[]
}

const WhatsApp: FC = () => {
  const { waId } = useParams<{ waId?: string }>()
  const [recipientsState, setRecipientsState] = useState<TRecipient[]>([])
  const [messageData, setMessageData] = useState({ text: '', name: '' })

  const updateStatus = (index: number, newStatus: TStatus) => {
    setRecipientsState((prev) => [
      ...prev.slice(0, index),
      { ...prev[index], status: newStatus },
      ...prev.slice(index + 1),
    ])
  }

  const getData = async () => {
    const data = await getAxiosSingle(`${WHATSAPP_URL}/${waId}`)
    // const data = dataMock
    setMessageData({ text: data.message, name: name })
    const recs = parseRecipients(data.users as TRecipientBack[])
    setRecipientsState(recs)
  }

  useEffect(() => {
    getData()
  }, [])

  return (
    <div className={styles.page}>
      <div className={styles.pageWrap}>
        <div className={'title'}>{messageData.name}</div>
        <Message text={messageData.text} />
        <Recipients
          recipients={recipientsState}
          updateStatus={updateStatus}
          // text={messageData.text}
        />
      </div>
    </div>
  )
}

export default WhatsApp
