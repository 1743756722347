import { FC } from 'react'

import ScrollArea from 'containers/ScrollArea/ScrollArea'
import Block from '../Block/Block'
import Question from '../Question/Question'
import Button from 'components/Button/Button'

import styles from './SurveyBuilder.module.scss'
import buttonStyles from 'components/Button/ButtonThemes.module.scss'
import useSurveyContext from '../Context'
import BlocksDropdown from '../BlocksDropdown/BlocksDropdown'

const SurveyBuilder: FC = () => {
  const { answers, currentPage, config, setNewAnswers, handleSubmit, goToPrevPage, goToNextPage } =
    useSurveyContext()
  const loaded = config.length > 0 // заменить на нормальтый стату загрузки

  const getAnswersToThisQuestion = (id: string) => {
    return answers.filter((iterated) => iterated.question === id)
  }

  const handleChangeOneAnswer = (questionId: string, answerText: string) => {
    const currentAnswers = getAnswersToThisQuestion(questionId)
    const newAnswer = {
      question: questionId,
      answer: answerText,
    }
    const answersUpd = !currentAnswers[0]
      ? [...answers, newAnswer]
      : answers.map((answerIterate) => {
          if (answerIterate.question === questionId) {
            return {
              ...answerIterate,
              answer: answerText,
            }
          } else return answerIterate
        })
    setNewAnswers(answersUpd)
  }

  const handleChangeCheckAnswer = (questionId: string, answerText: string, checked: boolean) => {
    const newAnswer = {
      question: questionId,
      answer: answerText,
    }
    if (!checked) {
      setNewAnswers([...answers, newAnswer])
    } else {
      const answersUpd = answers.filter(
        (iterateAnswer) =>
          !(iterateAnswer.question === questionId && iterateAnswer.answer === answerText)
      )
      setNewAnswers(answersUpd)
    }
  }

  return (
    <div className={styles.body}>
      {loaded ? (
        (() => {
          const currentBlock = config[currentPage]
          const { id: blockId, questions } = currentBlock
          const blockNumber = currentPage + 1
          const itsFirstPage = currentPage === 0
          const itsLastPage = currentPage === config.length - 1
          return (
            <div className={styles.pageContainer}>
              <BlocksDropdown />
              <ScrollArea>
                <div key={blockId}>
                  <div className={styles.surveyPage}>
                    <Block block={currentBlock} blockNumber={blockNumber} />
                    {questions &&
                      questions.map((question) => {
                        const { id: questionId } = question
                        const asnwersToThisQuestion = getAnswersToThisQuestion(questionId)
                        return (
                          <Question
                            key={questionId}
                            questionId={questionId}
                            blockId={blockId}
                            question={question}
                            handleChangeOneAnswer={handleChangeOneAnswer}
                            handleChangeCheckAnswer={handleChangeCheckAnswer}
                            asnwers={asnwersToThisQuestion}
                          />
                        )
                      })}
                    <div className={styles.blockFooter}>
                      {!itsFirstPage && (
                        <Button
                          modificator={buttonStyles.theme_secondary_but_darker}
                          onClick={goToPrevPage}
                        >
                          Назад
                        </Button>
                      )}
                      {!itsLastPage && <Button onClick={goToNextPage}>Далее</Button>}
                      {itsLastPage && <Button onClick={handleSubmit}>Завершить</Button>}
                    </div>
                  </div>
                </div>
              </ScrollArea>
            </div>
          )
        })()
      ) : (
        <p>А вот ничего и нет ¯\_(ツ)_/¯</p>
      )}
    </div>
  )
}

export default SurveyBuilder
