import { FC } from 'react'
import cx from 'clsx'

import styles from './Main.module.scss'
import { PAGES_DATA } from 'pages'
import btnStyles from 'components/Button/Button.module.scss'
import { Link } from 'react-router-dom'

const Main: FC = () => {
  const client = process.env.REACT_APP_CLIENT?.toLowerCase()
  const clientName = client ? client[0].toUpperCase() + client.slice(1) : '🤔'
  return (
    <div className={styles.main}>
      <div className={styles.container}>
        <p className={cx('text_30_400', styles.title)}>Oncall Pages</p>
        <p className={cx('text_20_400', styles.title)}>{clientName}</p>
        <div className={styles.btnsContainer}>
          {Object.keys(PAGES_DATA)
            .filter((e) => e !== 'MAIN')
            .map((pageName) => {
              const { link } = PAGES_DATA[pageName as keyof typeof PAGES_DATA]

              return (
                <Link
                  to={link}
                  target="_blank"
                  className={cx(styles.linkButton, btnStyles.button)}
                  key={pageName}
                >
                  {pageName}
                </Link>
              )
            })}
        </div>
      </div>
    </div>
  )
}

export default Main
