export type TStatus = 'new' | 'send' | 'canceled' | 'old'
export type TStatusPriority = { [k in TStatus]: number }

export type TRecipient = {
  userId: string
  name: string
  speciality: string
  phone: string
  status: TStatus
  text: string
}

export type TRecipientBack = {
  userId: string
  firstName: string
  middleName: string
  lastName: string
  speciality: string
  phone: string
  text: string
}

export enum WAUnsubDto {
  CHANGE_NUMBER = 'Сменился номер',
  STOP_WA = 'Отказался от WA',
  // STOP_VISITS = 'Больше не посещается',
  NOT_WORKING = 'Не работает',
  NOT_MY_CLIENT = 'Не мой клиент',
}
