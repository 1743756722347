export interface IOption {
  label: string
  id: string
}

export type TObj<T, U extends string | number = string> = {
  [key in U]: T
}

export enum Align {
  LEFT,
  RIGHT,
  TOP_CENTER,
  BOTTOM_CENTER,
  FULL,
}
