import { FC } from 'react'
import cx from 'clsx'

import styles from './WelcomeBack.module.scss'
import useUnsubContext from 'pages/UnsubscribeTable/UnsubContext'

const WelcomeBack: FC = () => {
  const { email } = useUnsubContext()
  return (
    <div className={styles.content}>
      <div className={styles.textBlock}>
        <p className={cx(styles.text, 'text_36_600')}>Ваши предпочтения учтены!</p>
        <p className={cx(styles.text, 'text_36_600')}>Все изменения успешно сохранены.🌟</p>
        <p className={cx(styles.email, 'text_2')}>{email}</p>
      </div>
    </div>
  )
}

export default WelcomeBack
