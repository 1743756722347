import { useState, useEffect, ChangeEvent } from 'react'
import { TSubscription } from './types'
import { UNSUB_URL } from 'constants/url'
import { getAxiosSingle, postAxiosSingle } from 'utils/axios'
import { useParams } from 'react-router-dom'

const pageStatuses = {
  GOODBY: 'GOODBY',
  WELCOME_BACK: 'WELCOME_BACK',
  UNSUBING: 'UNSUBING',
}

const { GOODBY, WELCOME_BACK, UNSUBING } = pageStatuses

const useUnsubscribe = () => {
  const { unsubId } = useParams<{ unsubId?: string }>()

  const [page, setPage] = useState(UNSUBING)
  const [email, setEmail] = useState('')
  const [subscriptions, setSubscriptions] = useState<TSubscription[]>([])
  const [prevSubscriptions, setPrevSubscriptions] = useState<TSubscription[]>([])
  const [initSubscriptions, setInitSubscriptions] = useState<TSubscription[]>([])
  const [checkAllUnsub, setCheckAllUnsub] = useState(false)
  const [subAgainStep, setSubAgainStep] = useState(false)

  const [titleHead, ...headers] = subscriptions?.[0] ? Object.keys(subscriptions[0]) : []

  const changeAllChecks = (value: boolean) =>
    subscriptions.map((subs) => {
      const { title, description, deletedAt, id, updatedAt, createdAt, ...rest } = subs
      const assigments = Object.keys(rest).reduce((acc, key) => {
        acc[key] = value
        return acc
      }, {} as Record<string, boolean>)

      return {
        description,
        title,
        deletedAt,
        id,
        updatedAt,
        createdAt,
        ...assigments,
      } as TSubscription
    })

  const handleResetToInit = () => {
    setSubscriptions(initSubscriptions), setPrevSubscriptions(initSubscriptions)
  }

  const handleSave = async () => {
    await postAxiosSingle(`${UNSUB_URL}/${unsubId}`, subscriptions)
    if (subAgainStep) {
      setPage(WELCOME_BACK)
    } else {
      setPage(GOODBY)
    }
  }

  const handleUnsubscribeAll = async () => {
    const allUnsubs = changeAllChecks(false)
    await postAxiosSingle(`${UNSUB_URL}/${unsubId}`, allUnsubs)
    setPage(GOODBY)
  }

  const handleSubscribeAll = async () => {
    const allUnsubs = changeAllChecks(true)
    await postAxiosSingle(`${UNSUB_URL}/${unsubId}`, allUnsubs)
    setPage(UNSUBING)
  }

  const handleReturnToSubscribePage = () => {
    setPage(UNSUBING)
  }

  const handleReturnToSubscribeWithAllChecks = () => {
    setSubscriptions(changeAllChecks(true))
    setPrevSubscriptions(changeAllChecks(true))
    setSubAgainStep(true)
    setPage(UNSUBING)
  }

  const getData = async () => {
    const data = await getAxiosSingle(`${UNSUB_URL}/${unsubId}`)
    if (data) {
      const { email: emailRes, subscriptions: subscriptionsRes } = data

      setEmail(emailRes)
      setSubscriptions(subscriptionsRes)
      setPrevSubscriptions(subscriptionsRes)
      setInitSubscriptions(subscriptionsRes)
    }
  }

  const toggleCheck = (event: ChangeEvent<HTMLInputElement>) => {
    event.stopPropagation()
    const { title, type } = event.currentTarget.dataset
    if (!title || !type) return
    const newRows = subscriptions.map((row) => {
      if (row.title === title) {
        return {
          ...row,
          [type]: !row[type as keyof typeof row],
        }
      } else return row
    })

    setSubscriptions(newRows)
    setPrevSubscriptions(newRows)
  }

  const toggleAllUnsubCheck = () => {
    console.log({ checkAllUnsub }, { prevSubscriptions })
    const newState = !checkAllUnsub
    if (newState) {
      setSubscriptions(changeAllChecks(false))
    } else {
      setSubscriptions(prevSubscriptions)
    }
    setCheckAllUnsub(!checkAllUnsub)
  }

  useEffect(() => {
    getData()
  }, [])

  return {
    pageStatuses,
    email,
    page,
    subscriptions,
    subAgainStep,
    checkAllUnsub,
    headers,
    toggleCheck,
    changeAllChecks,
    setSubscriptions,
    handleSave,
    handleUnsubscribeAll,
    handleSubscribeAll,
    handleReturnToSubscribePage,
    handleResetToInit,
    handleReturnToSubscribeWithAllChecks,
    toggleAllUnsubCheck,
  }
}
export default useUnsubscribe
