export type TValidateErrors = {
  [key: string]: string[]
}

export enum EQuestionType {
  TEXT = 'TEXT',
  RADIO = 'RADIO',
  CHECKBOX = 'CHECKBOX',
  SCORE = 'SCORE',
  DROPDOWN = 'DROPDOWN',
  PHONE = 'PHONE',
  DATE = 'DATE',
  EMAIL = 'EMAIL',
}

export const questionTypeNames = {
  [EQuestionType.TEXT]: 'Текстовый ответ',
  [EQuestionType.RADIO]: 'Один вариант ответа',
  [EQuestionType.CHECKBOX]: 'Несколько вариантов ответа',
  [EQuestionType.SCORE]: 'Шкала',
  [EQuestionType.DROPDOWN]: 'Выпадающий список',
  [EQuestionType.PHONE]: 'Номер телефона',
  [EQuestionType.DATE]: 'Дата',
  [EQuestionType.EMAIL]: 'Почтовый адрес',
}

export type TQuestionBase = {
  id: string
  title: string
  require: boolean
}

export type TQuestionText = {
  answerText: string
  type: EQuestionType.TEXT
} & TQuestionBase

export type TQuestionCheckbox = {
  type: EQuestionType.CHECKBOX
  answerChoice: string[]
} & TQuestionBase

export type TQuestionRadio = {
  type: EQuestionType.RADIO
  answerChoice: string[]
} & TQuestionBase

export type TQuestionDropdown = {
  type: EQuestionType.DROPDOWN
  answerChoice: string[]
} & TQuestionBase

export type TQuestionDate = {
  type: EQuestionType.DATE
  date: string
} & TQuestionBase

export type TQuestionEmail = {
  type: EQuestionType.EMAIL
  email: string
} & TQuestionBase

export type TQuestion = TQuestionText | TQuestionCheckbox | TQuestionRadio

export type TBlock = {
  id: string
  title: string
  description: string
  questions: TQuestion[]
}

export type TConfig = TBlock[]

export type TAnswer = {
  question: string
  answer: string
}

export type TContext = {
  answers: TAnswer[]
  setNewAnswers: (newAnswers: TAnswer[]) => void
  finished: boolean
  config: TConfig
  surveyName: string
  handleSubmit: () => void
  currentPage: number
  goToPrevPage: () => void
  goToNextPage: () => void
  goToPage: (order: number) => void
  requireErrors: TValidateErrors
}

export enum EBlockStatus {
  ERROR = 'ERROR',
  SUCCES = 'SUCCES',
  IDLE = 'IDLE',
}
export interface BlockDDConfigElement {
  id: string
  title: string
  status: EBlockStatus.ERROR | EBlockStatus.SUCCES | EBlockStatus.IDLE
}
