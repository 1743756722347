import { FC, MouseEvent, useRef, useState } from 'react'
import cx from 'clsx'
import styles from './BlocksDropdown.module.scss'
import { IconArrow } from 'assets/icons'
import useSurveyContext from '../Context'
import { EBlockStatus } from '../types'
import useClickOutside from 'hooks/useClickOutside'
import { getBlockDDConfig } from './utils'

const BlocksDropdown: FC = () => {
  const { config, currentPage, requireErrors, goToPage } = useSurveyContext()
  const containerRef = useRef<HTMLDivElement>(null)
  const [open, setOpen] = useState(false)

  const handleOutsideClick = () => setOpen(false)
  const handleTriggerClick = () => setOpen(true)

  const handleDropRowClick = (e: MouseEvent<HTMLDivElement>) => {
    const target = e.currentTarget as HTMLDivElement
    const { id } = target.dataset
    const order = config.findIndex((block) => block.id === id)
    goToPage(order)
  }

  useClickOutside(open, containerRef, handleOutsideClick)

  const ddConfig = getBlockDDConfig(config, requireErrors)

  const currentBlock = ddConfig[currentPage]

  return (
    <div className={styles.ddContainer}>
      <div className={styles.blocksDropdown}>
        <div className={styles.row} onClick={handleTriggerClick}>
          <span className={styles.blockTitle}>{currentBlock.title}</span>
          <IconArrow />
        </div>

        {open &&
          ddConfig.map((block, index) => {
            const { id, title, status } = block
            const statusText =
              status === EBlockStatus.ERROR ? '😡' : status === EBlockStatus.SUCCES ? '🐸' : '😴'
            const itsCurrentBlock = id === currentBlock.id
            return (
              <div
                className={cx(styles.row, styles.ddRow, { [styles.currentRow]: itsCurrentBlock })}
                key={id}
                onClick={handleDropRowClick}
                data-id={id}
              >
                <span className={styles.blockTitle}>{title}</span>
                {statusText}
              </div>
            )
          })}
      </div>
    </div>
  )
}

export default BlocksDropdown
