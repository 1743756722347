import { FC, useEffect, useRef, useContext, useState } from 'react'
import cx from 'classnames'

import PortalWrap from 'containers/PortalWrap'

import useClickOutside from 'hooks/useClickOutside'
import screenSizeContext from 'context/screenSizeContext'

import styles from './DropDown.module.scss'
import { Align } from 'types'

interface DropDown {
  triggerNode: React.ReactChild
  align?: Align
  mouseLeave?: boolean
  customStyles?: { [key: string]: string }
  disable?: true
  isMenuOpened: boolean
  handleTriggerClick: (event: any) => void
  handleOutsideClick: (event: any) => void
  handleMenuClick: (event: any) => void
  onMouseLeave?: (event: any) => void
}

const gap = 10

const DropDown: FC<DropDown> = ({
  children,
  triggerNode,
  mouseLeave,
  customStyles = {},
  align = Align.LEFT,
  disable = false,
  isMenuOpened,
  handleTriggerClick,
  handleOutsideClick,
  handleMenuClick,
  onMouseLeave,
}) => {
  const triggerRef = useRef<HTMLElement>(null)
  const containerRef = useRef<HTMLDivElement>(null)
  const windowSize: string = useContext(screenSizeContext)

  const [position, setPosition] = useState({ top: 0, left: 0 })

  useClickOutside(isMenuOpened, containerRef, handleOutsideClick)

  useEffect(() => {
    if (!containerRef.current) return
    if (!triggerRef.current) return
    const containerRect = containerRef.current.getBoundingClientRect()
    const triggerPosition = triggerRef.current.getBoundingClientRect()

    const topPos = triggerPosition.top + window.scrollY
    const pageHeight = document.body.offsetHeight
    const containerHeight = containerRect.height
    const padding = triggerPosition.height + gap
    const top = containerHeight + topPos > pageHeight ? topPos - containerHeight : topPos + padding
    setPosition({ top, left: triggerPosition.left })
  }, [isMenuOpened])

  return (
    <>
      <div
        className={cx(
          styles.triggerContainer,
          {
            [styles.triggerContainerActive]: isMenuOpened,
            [customStyles.triggerContainerActive]: isMenuOpened,
            [styles.disabled]: disable,
          },
          customStyles.triggerContainer
        )}
        onClick={handleTriggerClick}
        ref={triggerRef as React.RefObject<HTMLDivElement>}
      >
        {triggerNode}
      </div>
      {isMenuOpened && (
        <PortalWrap>
          <div
            className={styles.taskHoverBlock}
            onMouseDown={(e) => e.preventDefault()}
            onMouseLeave={onMouseLeave}
            onClick={handleMenuClick}
            style={position}
            tabIndex={0}
            ref={containerRef}
          >
            {children}
          </div>
        </PortalWrap>
      )}
    </>
  )
}

export default DropDown
